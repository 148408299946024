import React, { UseEffect, useState } from "react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../api/api";

// import Footer from "../../components/Footer";
// import Logo from "../../components/Logo";
// import ReactLoading from 'react-loading';
// import NavHeader from "./components/NavHeader";

const PortalRegister = () => {
  const [surname, setSurname] = useState("");
  const [othernames, setOthernames] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [programmeType, setProgrammeType] = useState("");
  const [matNum, setMatNum] = useState("");
  const [graduation_year, setgraduation_year] = useState("");
  
  const [loading, setLoading] = useState(false);

  const codee = phone.slice(0, 3).toString();

  const navi = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const registerApplicant = async (e) => {
    e.preventDefault();
    if (password !== confirmPass) {
      return toast.warning("Passwords do not match", warnProp);
    }
    try {
      setLoading(true);
      let res = await api.post("/clearance/register", {
        surname,
        othernames,
        email,
        phone,
        program_type: programmeType,
        matric_num: matNum,
        graduation_year: graduation_year
        
      });
      setLoading(false);
      toast.success("Account Created", successProp);
      navi("/", {replace:true});
    } catch (error) {
      toast.error(error.response.data.message, errorProp);
      setLoading(false);
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(/assets/images/dspg_gate.jpg)`,
    height: "100%    !important",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity:'01',
    minHeight:"120vh",
    paddingTop:"1rem"
}

  return (
    <>
    {/* <NavHeader /> */}
    <div
      style={backgroundStyle}
    >
        <div style={{ content: '', backgroundSize: 'cover', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0,backgroundColor: "rgba(0,0,0,0.4)"}}></div>
      <div class="container">
        <div class="row vh-100 d-flex justify-content-center">
          <div class="col-8 align-self-center">
            <div class="auth-page">
              <div class="card auth-card shadow-lg" style={{opacity:'0.9'}}>
                <div class="card-body">
                  <div class="px-3">
                    <div class="text-center auth-logo-text">
                      <h4 class="mt-0 mb-3 mt-5">Final Clearance Data Capture</h4>
                    </div>

                    <form
                      class="form-horizontal auth-form my-4"
                      onSubmit={registerApplicant}
                    >
                      <div class="form-group">
                        <label for="username">Surname</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-user"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            name="surname"
                            placeholder="Enter surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="username">Othernames</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-user"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            name="othername"
                            id="username"
                            placeholder="Enter othernames"
                            value={othernames}
                            onChange={(e) => setOthernames(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="useremail">Email</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-mail"></i>
                          </span>
                          <input
                            type="email"
                            class="form-control"
                            name="useremail"
                            id="useremail"
                            placeholder="Enter Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="new-password"
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="useremail">Phone</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-mail"></i>
                          </span>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            autoComplete="new-password"
                            required
                          />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="mo_number">Programme Type</label>
                        <div class="input-group mb-3">
                        <span class="auth-form-icon">
                            <i class="dripicons-school"></i>
                          </span>
                          <select
                              class="form-control"
                              value={programmeType}
                              onChange={(e) => setProgrammeType(e.target.value)}
                            >
                              <option>--Select--</option>
                              <option value="HND">HND</option>
                              <option value="ND">ND</option>
                            </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="useremail">Matric Number</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-mail"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            name="useremail"
                            id="useremail"
                            placeholder="Enter your matric number"
                            value={matNum}
                            onChange={(e) => setMatNum(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                        <div class="form-group">
                          <label for="grad_year">Year of graduation</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-mail"></i>
                            </span>
                            <input
                              type="number"
                              class="form-control"
                              name="useremail"
                              id="grad_year"
                              max={2024}
                              min={1990}
                              placeholder="Enter your year of graduation"
                              value={graduation_year}
                              onChange={(e) => setgraduation_year(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                      <div class="form-group mb-0 row d-flex justify-content-center">
                        {loading ? (
                          <div class="spinner-border text-success" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        ) : (
                          <div class="col-12 mt-2">
                            <button
                              class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
                              type="submit"
                            >
                              Register <i class="fas fa-sign-in-alt ml-1"></i>
                            </button>
                          </div>
                        )}
                      </div>
                      <ToastContainer />
                    </form>
                  </div>

                  <div class="m-3 text-center text-muted">
                    <p class="">
                      Already have an account ?{""}
                      <Link to="/" class="text-primary ml-2">
                        Log in
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    </div>
</>
  );
};

export default PortalRegister;
