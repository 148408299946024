import './App.css';
import {Routes, Route} from "react-router-dom"
import PortalWrapper from './pages/PortalWrapper';
import PortalLogin from './pages/PortalLogin';
import Dashboard from './pages/setup/Dashboard';
import SchoolFees from './pages/setup/SchoolFees';
import PaymentVerification from './pages/verification';
import SuccessPayment from './components/SuccessPayment';
import CourseRegistration from './pages/setup/CourseRegistration';
import Complaint from './pages/setup/Complaint';
import Accomodation from './pages/setup/Accomodation';
import { ToastContainer } from 'react-toastify';
import Library from './pages/setup/Library';
import SchoolFeeInvoice from './pages/SchoolFeeInvoice';
import HostelInvoice from './pages/HostelInvoice';
import UpdateBioData from './pages/setup/UpdateBioData';
import AllPayments from './pages/setup/invoice/AllPayments';
import ResetPassword from './pages/ResetPassword';
import NewPassword from './pages/NewPassword';
import CourseRegPrintout from './pages/setup/Print/CourseRegPrintout';
import AllHostelPayments from './pages/setup/invoice/AllHostelPayment';
import GeneralReceipt from './pages/GeneralReceipt';
import PortalRegister from './pages/PortalRegister';

function App() {
  return (
    <div className="App">
      <ToastContainer/>
      <Routes>

        <Route exact path='/clearance/invoice/fees' element={<SchoolFeeInvoice/>}/>
        {/* <Route exact path='/invoice/hostel' element={<HostelInvoice/>}/> */}
        {/* <Route exact path='/receipt' element={<GeneralReceipt/>}/> */}
        {/*Verification*/}
        <Route exact path='/paystack/verify' element={<PaymentVerification/>}/>
        {/*success*/}
        <Route exact path='/successful' element={<SuccessPayment/>}/>

        {/*Reset password*/}
        <Route exact path="/reset-password" element={<ResetPassword/>}/>
        <Route exact path="/password/reset/:uidb64/:token" element={<NewPassword/>}/>


        <Route exact path='' element={<PortalLogin/>}/>
        <Route exact path='/clearance/register' element={<PortalRegister/>}/>
        <Route exact path='/clearance' element={<PortalWrapper/>}>
          <Route exact path='' element={<Dashboard/>}/>
          <Route exact path='payment' element={<SchoolFees/>}/>
          {/* <Route exact path='/course-reg' element={<CourseRegistration/>}/> */}
          <Route exact path='complaint' element={<Complaint/>}/>
          {/* <Route exact path='/accommodation' element={<Accomodation/>}/> */}
          {/* <Route exact path='/accommodation/history' element={<AllHostelPayments/>}/> */}
          {/* <Route exact path='library' element={<Library/>}/> */}
          {/* <Route exact path='/biodata' element={<UpdateBioData/>}/> */}
          <Route exact path='history/payments' element = {<AllPayments/>}/>
          {/* <Route exact path='/course-reg/print' element={<CourseRegPrintout/>}/> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
