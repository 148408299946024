import api from "../api";

export const StudentLogin = async ({username, password}) => {
    try {
        const { data } = await api.post(`/student/login`, {username, password})

        return data;
    } catch (error) {
        throw error.response.data;
    }
}

export const GetStudentDetails = async (id) => {
    try {
        const { data } = await api.get(`/clearance/student/${id}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}

export const GeneralPaymentDetails = async () => {
    try {
        const { data } = await api.get(`/setup/view/fees/`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
export const InitiateSchoolFeePayment = async ({student_id, payment_option}) => {
    try {
        const { data } = await api.post(`/student/school-fee`, {student_id, payment_option})

        return data;
    } catch (error) {
        throw error.response.data;
    }
}

export const VerifyPayments = async (ref) => {
    try {
        const { data } = await api.get(`/paystack/verify/${ref}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}