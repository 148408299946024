import React from "react";

const StudentInfo = ({data}) => {
  return (
    <>
      <p className="breadcrumb-item active">Student Information</p>

      <div class="form-group row">
        <div class="col-md-6">
          <label>Matriculation Number</label>
          <input
            type="text"
            placeholder={data?.matric_num}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-6">
          <label>Graduation Year</label>
          <input
            type="text"
            placeholder={data?.graduation_year}
            class="form-control"
            disabled
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label>Program</label>
          <input
            type="number"
            placeholder={data?.program_type}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-6">
          <label>Department</label>
          <input
            type="text"
            placeholder={data?.department}
            class="form-control"
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default StudentInfo;
