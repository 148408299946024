import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import {
  GeneralPaymentDetails,
  InitiateSchoolFeePayment,
} from "../../api/student";
import SchoolFeeComponent from "../../components/SchoolFeeComponent";
import Footer from "../../components/Footer";
import { getCurrentUser } from "../../utils/authService";
import { toast } from "react-toastify";
import api from "../../api/api";
import StudentInfo from "../../components/StudentInfo";
import useDetails from "../../hooks/useDetails";
import { paymentOptions } from "../../utils/appParams";

const SchoolFees = () => {
  const user = getCurrentUser();
  const [schoolFee, setSchoolFee] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [btnText, setBtnText] = useState("Proceed to payment");

  const { data } = useDetails();

  const paySchoolFee = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiateSchoolFeePayment({
        student_id: user,
        payment_option: paymentType,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  const makePayment = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await api.post("/clearance/make-payment", {
        student_id: user,
        payment_type: paymentType,
      });
      console.log("payment oh", data);
      const { status, reference } = data;
      let {authorization_url} = data.data
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  

  const handleSelection = (payment) => {
    setPaymentType(payment.target.value);
    console.log(payment.target.value);
  };

  return (
    <>
      <div class="page-wrapper">
        {data && (
          <div class="page-content-tab">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <div class="float-right">
                      <ol class="breadcrumb">
                        {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                        <li class="breadcrumb-item active">Payment</li>
                      </ol>
                    </div>
                    <h4 class="page-title">Payment</h4>
                  </div>
                  <div class="card p-5" style={{ padding: "10px" }}>
                    <StudentInfo data={data?.data} />

                    <div className="col-lg-12 bg-light p-5">
                      <h4>Select the payment you want to make</h4>
                      <select
                        className="form-control col-lg-6"
                        value={paymentType}
                        onChange={handleSelection}
                      >
                        <option value="">--Select Payment--</option>
                        {paymentOptions?.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {paymentType && (
                      <>
                        <div class="mt-3">
                          <button
                            class="btn btn-primary btn-raised waves-effect w-full"
                            onClick={makePayment}
                            disabled={loading}
                          >
                            {btnText} <i class="fa fa-long-arrow-right"></i>
                          </button>
                        </div>
                      </>
                    )}
                    <hr className="border border-gray" />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default SchoolFees;
